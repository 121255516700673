<template>
  <div class="page">
    <div class="box">
      <!-- 有数据时 -->
      <div class="left" v-loading="loading">
        <!-- 通知栏 -->
        <div class="top" v-if="notice && isShow">
          <notice-bar
            class="topNoticeBar"
            :scrollable="true"
            :speed="notice.length > 50 ? 50 : notice.length"
            :outview="true"
            :text="notice"
            color="#000"
            bg-color="transparent"
            :left-icon="$http.baseURL + '/static/images/web/2x.png'"
          />
          <img @click="isShow = false" class="guan" src="@image/guanbi.svg" />
        </div>

        <!-- 轮播(走马灯) -->
        <el-carousel
          :autoplay="false"
          arrow="always"
          height="340px"
          v-if="recommend.length"
        >
          <el-carousel-item
            class="carousel-item"
            v-for="(val, i) in recommendFilter"
            :key="val.id"
          >
            <div class="box" v-for="(items, ind) in val" :key="ind">
              <img
                class="photo"
                @click="goPersonal(items)"
                :src="items.photo ? items.photo : require('@image/default.png')"
                style="cursor: pointer"
              />
              <span class="title">{{ items.name }}</span>
              <span class="position" v-if="items.title_name">{{
                items.title_name
              }}</span>

              <span class="subTitle" v-if="items.department_name">{{
                items.department_name
              }}</span>
              <span
                class="subTitle"
                style="height: 14px; display: block; margin-bottom: 10px"
                v-else
              ></span>
              <span
                style="
                  display: inline-blokc;
                  height: 40px;
                  width: 140px;
                  line-height: 20px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                "
                >{{ items.company }}</span
              >

              <span
                v-if="items.attention"
                @click="postDiscoverAttention(items)"
                class="button1"
              >
                已关注</span
              >
              <span @click="postDiscoverAttention(items)" v-else class="button"
                >关注</span
              >
              <img
                @click="deleteUser(i, ind)"
                class="guan"
                src="@image/guanbi2.png"
              />
            </div>
          </el-carousel-item>
        </el-carousel>

        <div>
          <!-- 发现列表 -->
          <list
            v-if="page == 1"
            :discoveryList="discoverDistolist"
            :page="1"
            :page-size="10"
            :recommend="true"
          ></list>

          <list
            :discoveryList="discoveryList"
            :page="page"
            :page-size="limit"
          ></list>

          <!-- 分页 -->
          <div class="foot-page" v-if="total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              current-page.sync="5"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- 无数据时 -->
      <div class="left" v-if="!loading && !discoveryList.length">
        <!-- 暂无数据 -->
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>

      <!-- 右侧个人信息区 -->
      <div class="right">
        <!-- 个人信息 -->
        <div class="box">
          <div class="bottom">
            <img class="headPortrait" :src="userInfoAvatar" />
            <span>{{ userInfo.nickname ? userInfo.nickname : "请登录" }}</span>
            <div class="group">
              <div class="left" @click="xin.goUrl('/userCenter?index=4')">
                <span>{{ userParam.follow ? userParam.follow : 0 }}</span>
                <span class="subTitle">粉丝</span>
              </div>
              <div class="line"></div>
              <div class="left" @click="xin.goUrl('/userCenter?index=5')">
                <span>{{ userParam.attention ? userParam.attention : 0 }}</span>
                <span class="subTitle">关注</span>
              </div>
            </div>
            <img @click="gofabu" class="button" src="@image/fabu.png" />
          </div>
        </div>
        <!-- 信息区 -->
        <messageTwo></messageTwo>
      </div>
    </div>

    <!-- 对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
    >
      <span>学习请先进行身份认证</span>
      <div class="popupsButton">
        <span @click="dialogVisible = !dialogVisible" class="popupsJudgment"
          >取消</span
        >
        <span @click="go" class="popupsJudgment">认证</span>
      </div>
    </el-dialog>
    <!-- 对话框end -->
  </div>
</template>

<script>
import messageTwo from "@components/message/message";
import list from "@components/common/list";
import { mapState, mapActions } from "vuex";
import { NoticeBar } from "vue-easy-notice-bar";

export default {
  components: {
    messageTwo,
    list,
    NoticeBar,
  },
  data() {
    return {
      dialogVisible: false, // 控制对话框的显示与隐藏
      discoveryList: [], // 发现列表
      discoverDistolist: [],
      recommend: "", // 推荐医生
      notice: "",
      isShow: true, // 控制通知栏的显示和隐藏
      // islod: true, //  防止触发多次点击
      page: 1,
      limit: 10, // 每页显示条目个数
      total: 0, // 总页数
      loading: true, // 加载数据时显示动效
    };
  },

  async created() {
    // this.xin.isLoadding(true)
    this.loading = true;
    // 用户信息
    let token = localStorage.getItem("token") || "";
    if (token) {
      // 用户关注信息
      this.getPersonal();
    }
    // 通告
    this.getNotice();
    this.docterList();
    await this.findList();
    this.loading = false;
    // this.xin.isLoadding(false)
  },

  async mounted() {
    // this.xin.isLoadding(true)
    this.loading = true;
    // 用户信息
    let token = localStorage.getItem("token") || "";
    if (token) {
      // 用户关注信息
      this.getPersonal();
    }
    // 通告
    this.getNotice();
    this.docterList();
    await this.findList();
    this.loading = false;
    // this.xin.isLoadding(false)
    this.discoverDistolists();
  },

  computed: {
    ...mapState("userManagement", ["userInfo", "userParam"]),
    recommendFilter() {
      let list = [],
        arr = [];
      if (this.recommend.length > 3) {
        this.recommend.forEach((v, i) => {
          arr.push(v);
          if ((i + 1) % 4 == 0 || i == this.recommend.length - 1) {
            list.push(arr);
            arr = [];
          }
        });
      } else {
        list = [this.recommend];
      }

      return list;
    },
    userInfoAvatar() {
      return this.userInfo.avatar
        ? this.userInfo.avatar
        : this.$http.baseURL + "/static/images/web/1.png";
    },
  },

  methods: {
    //认证弹窗隐藏
    handleClose(done) {
      done();
    },
    // 认证
    go() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/authentication",
      });
    },
    // 关注进入个人中心
    goPersonal(item) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      if (item.attention == true) {
        let url = "/found-personal?id=" + item.user_id;
        this.xin.goUrl(url);
      } else if (this.userInfo.id == item.user_id) {
        this.xin.goUrl("/creation");
      } else {
        this.$message({
          message: "关注后即可查看",
          type: "error",
        });
      }
    },

    // 发布创作
    gofabu() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      if (this.userInfo.company && this.userInfo.company.status == 1) {
        this.$router.push({
          path: "/found-release-index",
        });
      } else {
        this.dialogVisible = true;
      }
    },

    ...mapActions("userManagement", ["getPersonal"]),

    // pageSize改变时会触发
    async handleSizeChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.findList();
      this.xin.isLoadding(false);
    },

    // currentPage改变时会触发
    async handleCurrentChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.findList();
      this.xin.isLoadding(false);
      // 滚动到顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 关注过滤
    // doctorListFn(e) {
    //   for (let i in this.recommend) {
    //     if (e.doctor.id == this.recommend[i].id) {
    //       this.recommend[i].attention = !this.recommend[i].attention;
    //     }
    //   }
    // },

    // 删除推荐关注医生
    deleteUser(i, ind) {
      this.recommend.splice(i * 4 + ind, 1);
    },

    // 发现列表
    async findList() {
      let res = await this.$findApi.findList({
        page: this.page,
        limit: this.limit,
      });
      if (res.code == 200) {
        let data = res.data.data || [];
        data.forEach((v) => {
          if (v.images) {
            v.images = JSON.parse(v.images);
          } else {
            v.images = [];
          }
        });
        this.discoveryList = data;
        this.total = res.data.total;
      }
    },

    // 发现推荐位
    async discoverDistolists() {
      let res = await this.$findApi.discoverDistolists();
      res.data.forEach((item) => {
        item.images = JSON.parse(item.images);
      });
      if (res.code == 200) {
        this.discoverDistolist = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    // 推荐医生
    async docterList() {
      let res = await this.$findApi.docterList();
      if (res.code == 200) {
        this.recommend = res.data || [];
      }
    },

    // 通告栏
    async getNotice() {
      let res = await this.$findApi.findNotice({
        type: 1,
      });
      if (res.code == 200) {
        let content = res.data;
        content = content.replace(/[\r\n]/g, ""); //去掉回车换行
        content = content.replace(/;/g, "  "); // 去掉最后;
        content = content.replace(/<[^>]+>/g, ""); // 去掉html标签;
        content = content.replace(/&nbsp/g, ""); // 去掉&nbsp空格符号;
        content = content.replace(/\s+/g, ""); //去掉空格
        this.notice = content;
      }
    },

    // 关注
    async postDiscoverAttention(item) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      // 防止多次发送请求
      // if (this.islod) {
      //   this.islod = false;
      let res = await this.$userApi.postDiscoverAttention({
        attention_id: item.user_id,
      });
      if (res.code == 200) {
        this.getPersonal();
        if (item.attention) {
          this.$message({
            message: "已取消关注",
            type: "success",
          });
        } else {
          this.$message({
            message: "关注成功",
            type: "success",
          });
        }
        item.attention = !item.attention;
        // this.islod = true;
        for (let i in this.discoveryList) {
          if (item.id == this.discoveryList[i].doctor.id) {
            this.discoveryList[i].attention = !this.discoveryList[i].attention;
            // }
          }
        }
      } else {
        this.$message({
          message: res.message,
          type: "info",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.video {
  width: 890px;

  height: 400px;
}

.top {
  margin-bottom: 20px;
}

.top /deep/ {
  .el-carousel {
    margin-bottom: -3px !important;
  }

  .el-carousel__container {
    background: #0000;
  }
}

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    padding: 70px 80px;
    box-sizing: border-box;
    margin-top: 320px;

    > .el-dialog__body {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .popupsButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      > .popupsJudgment {
        &:last-child {
          background: $--themeColor;
          border: none;
          color: #ffffff;
        }

        width: 170px;
        height: 50px;
        background: #ffffff;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: $--themeColor;
        font-size: 18px;
        text-align: center;
        line-height: 50px;
        margin-top: 60px;
      }
    }
  }

  .el-carousel__item .banner {
    width: 890px;
    height: 400px;
    object-fit: cover;
  }

  // .el-carousel__arrow {
  //   // display: none;
  // }
}

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-carousel__indicators {
    display: none;
  }

  .el-carousel__container {
    background: #fafafa;
  }

  .el-carousel {
    width: 100%;
    background: #fafafa;
  }
}

.foot-page {
  height: 40px;
  text-align: center;
  width: 100%;
}

.left {
  /deep/.carousel-item {
    display: flex;
    background: #fafafa;

    .box {
      &:first-child {
        margin-left: 56px;
      }

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 170px;
      height: 300px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 11px;
      margin: 30px 30px 30px 0;
      color: #333333;
      font-size: 14px;
      margin-right: 30px;

      > .photo {
        width: 99px;
        height: 99px;
        object-fit: cover;
        margin-top: 12px;
        border-radius: 50%;
      }

      > .title {
        font-size: 24px;
        margin: 6px 0;
      }

      > .position {
        font-size: 14px;
        color: $--themeColor;
        height: 24px;
        border: 1px solid $--themeColor;
        border-radius: 6px;
        text-align: center;
        margin-top: 6px;
        margin-bottom: 8px;
        padding: 4px 12px;
        box-sizing: border-box;
      }

      > .subTitle {
        color: #8f8f8f;
        margin-bottom: 10px;
        width: 120px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      > .button {
        cursor: pointer;
        color: #fff;
        font-size: 12px;
        width: 106px;
        height: 28px;
        background: $--themeColor;
        border: 1px solid $--themeColor;
        border-radius: 6px;
        margin-top: 8px;
        text-align: center;
        line-height: 27px;
      }

      > .button1 {
        cursor: pointer;
        color: #8f8f8f;
        font-size: 12px;
        width: 105px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-top: 8px;
        text-align: center;
        line-height: 28px;
      }

      > .guan {
        cursor: pointer;
        width: 12px;
        height: 12px;
        object-fit: none;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}

.page {
  > .box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;

    > .nullPage {
      width: 890px;
    }

    > .left {
      width: 890px;

      > .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 890px;
        height: 40px;
        background: #dbfbf8;
        padding: 10px;
        box-sizing: border-box;
        color: $--themeColor;
        font-size: 16px;

        > .message {
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 10px;
        }

        > .guan {
          cursor: pointer;
          width: 12px;
          height: 12px;
          object-fit: cover;
          margin-left: auto;
        }
      }

      .el-carousel {
        margin-bottom: 30px;
      }
    }

    > .right {
      margin-left: 16px;

      > .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 294px;
        background: #f2f2f2;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 20px;

        > .bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 264px;
          background: #ffffff;
          font-size: 14px;
          color: #333333;
          border-radius: 5px;
          padding: 20px 22px;
          box-sizing: border-box;
          font-size: 20px;

          span {
            cursor: pointer;
          }

          > .headPortrait {
            width: 60px;
            height: 60px;
            object-fit: cover;
            margin-bottom: 10px;
            border-radius: 50%;
          }

          > .group {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;

            > .left {
              display: flex;
              flex-direction: column;
              align-items: center;

              > .subTitle {
                color: #8f8f8f;
                font-size: 14px;
                margin-top: 8px;
              }
            }

            > .line {
              width: 2px;
              height: 30px;
              background: #f2f2f2;
              margin: 0 50px;
            }
          }

          > .button {
            cursor: pointer;
            width: 220px;
            height: 44px;
            object-fit: cover;
            margin-top: 10px;
          }
        }
      }

      > .box2 {
        display: flex;
        flex-direction: column;
        width: 294px;
        background: #f2f2f2;
        padding: 0 15px;
        box-sizing: border-box;

        > .group {
          &:first-child {
            margin-top: 15px;
          }

          display: flex;
          flex-direction: row;
          margin-bottom: 15px;

          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }

          > .right {
            display: flex;
            flex-direction: column;
            font-size: 14px;

            > .title {
              color: #333333;
              line-height: 20px;
            }

            > .time {
              color: #8f8f8f;
              margin-top: auto;
            }
          }
        }
      }
    }
  }

  .topNoticeBar {
    width: 100%;
    padding-left: 0;
  }
}
</style>
